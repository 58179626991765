import React from 'react';

const Socials = () => {
  return (
    <div className="px-1 flex gap-x-4 justify-end items-center text-gray-400">
      <a
        href="https://github.com/Kokuyo3"
        className="transition-colors ease-in hover:text-white focus:outline-none focus:ring-1 focus:ring-offset-1 focus:ring-blue-500 focus:ring-offset-blue-200"
      >
        <span className="sr-only">View my github</span>
        <svg
          width="30"
          height="30"
          viewBox="0 0 16 16"
          fill="currentColor"
          shapeRendering="geometricPrecision"
          imageRendering="optimizeQuality"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M8 0C3.58 0 0 3.58 0 8C0 11.54 2.29 14.53 5.47 15.59C5.87 15.66 6.02 15.42 6.02 15.21C6.02 15.02 6.01 14.39 6.01 13.72C4 14.09 3.48 13.23 3.32 12.78C3.23 12.55 2.84 11.84 2.5 11.65C2.22 11.5 1.82 11.13 2.49 11.12C3.12 11.11 3.57 11.7 3.72 11.94C4.44 13.15 5.59 12.81 6.05 12.6C6.12 12.08 6.33 11.73 6.56 11.53C4.78 11.33 2.92 10.64 2.92 7.58C2.92 6.71 3.23 5.99 3.74 5.43C3.66 5.23 3.38 4.41 3.82 3.31C3.82 3.31 4.49 3.1 6.02 4.13C6.66 3.95 7.34 3.86 8.02 3.86C8.7 3.86 9.38 3.95 10.02 4.13C11.55 3.09 12.22 3.31 12.22 3.31C12.66 4.41 12.38 5.23 12.3 5.43C12.81 5.99 13.12 6.7 13.12 7.58C13.12 10.65 11.25 11.33 9.47 11.53C9.76 11.78 10.01 12.26 10.01 13.01C10.01 14.08 10 14.94 10 15.21C10 15.42 10.15 15.67 10.55 15.59C13.71 14.53 16 11.53 16 8C16 3.58 12.42 0 8 0Z" />
        </svg>
      </a>
      <a
        href="https://www.linkedin.com/in/cheryl-tang-437825a2/"
        className="transition-colors ease-in hover:text-white focus:outline-none focus:ring-1 focus:ring-offset-1 focus:ring-blue-500 focus:ring-offset-blue-200"
      >
        <span className="sr-only">View my linkedin</span>
        <svg
          width="31"
          height="31"
          viewBox="0 0 3330 3330"
          fill="currentColor"
          shapeRendering="geometricPrecision"
          textRendering="geometricPrecision"
          imageRendering="optimizeQuality"
          fillRule="evenodd"
          clipRule="evenodd"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M1667 0c920 0 1667 746 1667 1667 0 920-746 1667-1667 1667C747 3334 0 2588 0 1667 0 747 746 0 1667 0zm-215 1336h342v175h5c48-86 164-175 338-175 361 0 428 225 428 517v596h-357v-528c0-126-3-288-186-288-186 0-214 137-214 279v537h-357V1336zm-247-309c0 102-83 186-186 186-102 0-186-83-186-186 0-102 83-186 186-186 102 0 186 83 186 186zm-371 309h371v1113H834V1336z" />
        </svg>
      </a>
    </div>
  );
};

export default Socials;
